<template>
  <v-container fluid class="fill-height hp-body-bg">
    <v-row>
      <v-col cols="12" md="8" lg="6" xl="4" class="mx-auto">
        <v-card class="rounded-card-with-shadow card-spacing mx-auto">
          <!-- <v-toolbar color="primary" elevation="0" dark>
            <logoSvg />
          </v-toolbar> -->
          <v-card-title>
            <h4 class="display-1 primary--text text-center w-100 mt-6">
              {{ $t("Welcome") }},
              <strong>{{ getUserName(me) }} {{ $t("to Talktime") }}</strong>
            </h4>
          </v-card-title>
          <v-card-text class="pa-8 text-center">
            <p class="body-1">
              {{ $t("Your therapist") }} {{ getUserName(myTherapist) }}
              {{ $t("is ready to meet you!") }}
              {{
                $t(
                  "You've taken a brave first step towards feeling better today. We’ll do everything we can to help and support you on your mental wellness journey."
                )
              }}
            </p>
          </v-card-text>
          <v-card-actions class="pb-8">
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              class="px-8 py-3"
              outlined
              elevation="0"
              :loading="loading"
              @click="confirmAction"
            >
              Continue
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import UserMixin from "@/mixins/UserMixin";
// import logoSvg from "@/components/svg/Logo"
import { mapActions } from "vuex";

export default {
  name: "WelcomeBack",
  // components: { logoSvg },
  mixins: [UserMixin],

  data: () => {
    return {
      loading: false,
    };
  },

  methods: {
    ...mapActions("user", ["setFirstTimeLogin"]),

    confirmAction() {
      this.loading = true;
      this.setFirstTimeLogin().then(() => {
        this.loading = false;
        this.$router.replace({ name: "Calendar" });
      });
    },
  },

  mounted() {
    if (this.isTherapist) {
      this.setGlobalLoading(true);
      this.setFirstTimeLogin().then(() => {
        this.loading = false;
        this.setGlobalLoading(false);
        this.$router.replace({ name: "Calendar" });
      });

      if (!this.me.verified) {
        this.$router.push({ name: "VerifyEmail" });
      } else {
        this.$router.replace({ name: "Calendar" });
      }
    }
  },
};
</script>

<style scoped>
/* .theme--light .hp-body-bg {
    box-shadow: inset 0 0 0 2000px rgba(255, 255, 255, 0.5);
    background-repeat: no-repeat;
    background-size: cover;

    background-image: url("../../assets/main.png");
  } */
</style>
